//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=block');
@import url('//fonts.googleapis.com/css2?family=Montserrat:wght@700;800&family=Open+Sans:ital,wght@0,400;0,600;1,400&family=Roboto+Slab&display=swap');

*:focus {
    outline: none;
}

html {
    overflow: hidden;
}

body {
    margin: 0;
    height: auto;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.025em;
}

h1,h2,h3,h4,h5,h6 {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.1;
    font-weight: 700;

    strong {
        color: #FF5800;
    }

    em {
        color: #5842F2;
        font-style: normal;
    }
}

h1 {
    font-size: 4rem;
}

h2 {
    font-size: 3rem;
}

h3 {
    font-size: 2rem;
}

.slides {
    background: #fff;
}

main {
    width: 100%;
    min-height: 100vh;
    margin: 0;
}

.video-slide {
    width: 100vw;
    height: 100vh;
    position: relative;

    .player {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
